<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-tabs class="tabsLicense">
          <b-tab
            :active="tabActive === 'Free' ? true : false"
            @click.prevent="tabActive = 'Free'"
          >
            <template slot="title">
              <span :style="freeSites.length >= 1 ? 'border: 0px !important;' : freeSites.length === 0 && tabActive === 'Free' ? 'border: 0px !important;' : 'color: rgb(167, 170, 185); border: 0px !important;'">Free</span>
            </template>
            <sites-license :sites-free.sync="freeSites" :sites-basic.sync="basicSites" :sites-pro.sync="proSites" :sites-custom.sync="customSites" :type.sync="tabActive" :loading-sites.sync="loadingSites" v-if="freeSites.length >= 1 && tabActive === 'Free'" />
            <h2
              style="text-align: left; color: rgb(120, 56, 255) !important;"
              v-else-if="freeSites.length === 0 && !loadingSites"
            >
              0 
              <span style="font-weight: initial; color: #656B85; font-size: 1rem !important;">sites</span>
            </h2>
          </b-tab>
          <b-tab
            :active="tabActive === 'Pro' ? true : false"
            @click.prevent="tabActive = 'Pro'"
          >
            <template slot="title">
              <span :style="proSites.length >= 1 ? 'border: 0px !important;' : proSites.length === 0 && tabActive === 'Pro' ? 'border: 0px !important;' : 'color: rgb(167, 170, 185); border: 0px !important;'">Licensed</span>
            </template>
            <sites-license :sites-free.sync="freeSites" :sites-basic.sync="basicSites" :sites-pro.sync="proSites" :sites-custom.sync="customSites" :type.sync="tabActive" :loading-sites.sync="loadingSites" v-if="proSites.length >= 1 && tabActive === 'Pro'" />
            <h2
              style="text-align: left; color: rgb(120, 56, 255) !important;"
              v-else-if="proSites.length === 0 && !loadingSites"
            >
              0 
              <span style="font-weight: initial; color: #656B85; font-size: 1rem !important;">sites</span>
            </h2>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BImgLazy, BButton, BAvatarGroup, BLink, BRow, BCol,
  BListGroup, BListGroupItem, BFormCheckbox, BCard, BCardText, BCardImgLazy, VBTooltip, BTabs, BTab,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText } from '@core/utils/filter'
import SitesLicense from './SitesLicense.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import moment from 'moment'
import { db, auth, firebase } from '@/firebase'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BImgLazy,
    BButton,
    BAvatarGroup,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardImgLazy,
    BTabs,
    BTab,
    vSelect,
    SitesLicense,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup() {
    return {
      avatarText,
    }
  },
  data() {
    return {
      titlePage: '',
      selectedPlan: '',
      proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
      optionsPlan: [
        'Free',
        'Basic',
        'Pro',
        'Custom license',
      ],
      tabActive: 'Free',
      freeSites: [],
      basicSites: [],
      proSites: [],
      customSites: [],
      timerchangesSites: null,
      userProjects: [],

      // table
      columns: [
        {
          label: '',
          field: 'imgSite',
          sortable: false,
          thClass: 'thTableLicenses',
          tdClass: 'tdTableLicenses',
        },
        {
          label: '',
          field: 'name',
          sortable: false,
          thClass: 'thTableSitesMembership',
          tdClass: 'tdTableSitesMembership',
        },
        {
          label: '',
          field: 'domain',
          sortable: false,
          thClass: 'thTableLicensesDomain',
          tdClass: 'tdTableLicensesDomain',
        },
        // {
        //     label: 'Current Errors',
        //     field: 'currentErrors',
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSites'
        // },
        // {
        //     label: 'Fixed Issues',
        //     field: 'fixedIssues',
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSites'
        // },
        // {
        //     label: 'Key',
        //     field: 'key',
        //     sortable: false,
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSitesKey'
        // },
        // // {
        // //     label: 'Alerts',
        // //     field: 'alerts',
        // //     thClass: 'thTableSites',
        // //     tdClass: 'tdTableSites'
        // // },
        // {
        //     label: 'Membership',
        //     field: 'membership',
        //     sortable: false,
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSites'
        // },
        // {
        //     label: 'Owned by',
        //     field: 'ownedBy',
        //     sortable: false,
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSitesOwned'
        // },
        {
          label: '',
          field: 'license',
          sortable: false,
          thClass: 'thTableSitesActionMembership',
          tdClass: 'tdTableSitesActionMembership',
        },
      ],
      rowsAtomic: [],
      rowSiteSelected: [],
      dir: false,
      searchSite: '',
      pageLength: 5,
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      shared: false,
      isBusy: false,

      // sidebar ownership
      idsite: '',
      showOpen: false,
      showOpenChangeProject: false,
      showOpenArchiveSite: false,
      showOpenMembersProject: false,

      // plans
      planActive: 0,
      status: 'annually',
      monthlyPlanShow: false,
      pricing: {
        basicPlan: {
          title: 'Free',
          img: require('@/assets/images/illustration/Pot1.svg'),
          subtitle: 'A simple start for everyone',
          monthlyPrice: 0,
          yearlyPlan: {
            perMonth: 0,
            totalAnual: 0,
          },
          planBenefits: [
            'Unlimited fixes',
            'Up to 3 pages crawl',
          ],
          popular: false,
        },
        standardPlan: {
          title: 'Basic',
          img: require('@/assets/images/illustration/Pot2.svg'),
          subtitle: 'For small to medium businesses',
          monthlyPrice: 123,
          yearlyPlan: {
            perMonth: 115,
            totalAnual: 1380,
          },
          planBenefits: [
            'Unlimited fixes',
            'Up to 10 pages crawl',
          ],
          popular: true,
        },
        enterprisePlan: {
          title: 'Pro',
          img: require('@/assets/images/illustration/Pot3.svg'),
          subtitle: 'Solution for big organizations',
          monthlyPrice: 140,
          yearlyPlan: {
            perMonth: 130,
            totalAnual: 1560,
          },
          planBenefits: [
            'Unlimited',
            'Up to 100 pages crawl',
          ],
          popular: false,
        },
        customPlan: {
          title: 'Custom License',
          img: require('@/assets/images/illustration/Pot3.svg'),
          subtitle: 'Solution for big organizations',
          monthlyPrice: 140,
          yearlyPlan: {
            perMonth: 130,
            totalAnual: 1560,
          },
          planBenefits: [
            'Unlimited',
            'Up to 100 pages crawl',
          ],
          popular: false,
        },
        qandA: [
          {
            question: 'Does my license automatically renew?',
            ans:
                        'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
          },
          {
            question: 'Can I store the item on an intranet so everyone has access?',
            ans:
                        'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
          },
          {
            question: 'Am I allowed to modify the item that I purchased?',
            ans:
                        'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
          },
        ],
      },
      statusSite: '',
      userData: '',
      loadingSites: true
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    searchSite(val) {
      setTimeout(() => {
        const collection = document.getElementsByClassName('vgt-text-disabled')
        if (collection[0] && val) {
          collection[0].innerHTML = 'No results found'
        }
      }, 1000)
    },
    idsite(val) {
      // console.log(val.membership)
      if(val.id) {
        db.collection('sites').doc(val.id).get()
        .then(docSite => {
          this.statusSite = docSite.data().status ? docSite.data().status : ''
        })
        .catch(error => {
          console.log(error)
        })
      }

      this.rowSiteSelected = []
      this.rowSiteSelected.push(val)

      if(val.membership === 'Free') {
        this.planActive = 0
      } else if(val.membership === 'Basic') {
        this.planActive = 1
      } else if(val.membership === 'Pro') {
        this.planActive = 2
      } else if(val.membership === 'Custom license') {
        this.planActive = 3
      }
    },
    tabActive(val) {
      if(val === 'Pro') {
        history.pushState(null, "", `/licenses/paid`)
      } else {
        history.pushState(null, "", `/licenses`) 
      }

      this.getStatusChangesSites()

      // horizontal scroll
      this.$nextTick(() => {
        $(".top-scroll-horizontal-license"+this.tabActive).scroll(() => {
          $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-license"+this.tabActive).scrollLeft());
        });

        $(".vgt-responsive").scroll(() => {
          $(".top-scroll-horizontal-license"+this.tabActive).scrollLeft($(".vgt-responsive").scrollLeft());
        });
      });

      setTimeout(() => {
        this.$nextTick(() => {
          $(".top-scroll-horizontal-license"+this.tabActive).scroll(() => {
            $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-license"+this.tabActive).scrollLeft());
          });

          $(".vgt-responsive").scroll(() => {
            $(".top-scroll-horizontal-license"+this.tabActive).scrollLeft($(".vgt-responsive").scrollLeft());
          });
        });
      }, (1500));
    }
  },
  created() {
    this.loadingSites = true
    const user = auth.currentUser
    if(user) {
      // console.log(this.$route.name);
      if(this.$route.name === 'memberships-paid') {
        this.tabActive = 'Pro'
      }

      db.collection('Users').doc(user.uid).get()
      .then(userDocCustomer => {
        var xUserCustomer = userDocCustomer.data().customerId ? userDocCustomer.data().customerId : ''
        if(xUserCustomer) {
          this.tabActive = 'Pro'
          history.pushState(null, "", `/licenses/paid`)
        }

        db.collection('Users').doc(user.uid).collection('projects').get()
        .then(userDoc => {
          // console.log(userDoc.size)
          this.freeSites = []
          this.basicSites = []
          this.proSites = []
          this.customSites = []
          var nextDue = moment(Date.now()).add(1, 'month').format('MM/DD/YYYY');
          this.userProjects = []
          var arraySitesAdded = []
          userDoc.forEach(eleUserProject => {
            var obj = eleUserProject.data()
            obj.id = eleUserProject.id
            this.userProjects.push(obj)
            db.collection('Projects').doc(eleUserProject.data().id).collection('sites').get()
            .then(queryArchiveProject => {
              queryArchiveProject.forEach(site => {
                if(!arraySitesAdded.includes(site.data().WebsiteURL)) {
                  arraySitesAdded.push(site.data().WebsiteURL)
                  var changeToFree = false
                  var statusCancel = site.data().cancelSub ? site.data().cancelSub : false
                  if(statusCancel) {
                    // console.log(statusCancel, site.data().due*1000);
                    var dueCancel = moment(site.data().due_end*1000)
                    var dateActual = moment()

                    // Diferencia en días
                    var differenceInMinutes = dueCancel.diff(dateActual, 'minutes');
                    // console.log(differenceInMinutes, dueCancel, dateActual);
                    if(differenceInMinutes < 0) {
                      changeToFree = true
                      db.collection('Projects').doc(eleUserProject.id).collection('sites').doc(site.id).update({
                        plan: 'Free',
                        planType: 0,
                        cancelSub: false,
                        failPay: false
                      })
                    }
                  }

                  var downgrade_date = site.data().downgrade_date ? site.data().downgrade_date : false
                  var downgrade_planed = false
                  if(downgrade_date) {
                    // console.log(statusCancel, site.data().due*1000);
                    var dueCancel = moment(downgrade_date*1000)
                    var dateActual = moment()

                    // Diferencia en días
                    var differenceInMinutes = dueCancel.diff(dateActual, 'minutes');
                    // console.log(differenceInMinutes, dueCancel, dateActual);
                    if(differenceInMinutes < 0) {
                      downgrade_planed = false
                      db.collection('Projects').doc(eleUserProject.id).collection('sites').doc(site.id).update({
                        failPay: false,
                        downgrade_date: ''
                      })
                    } else {
                      downgrade_planed = true
                    }
                  }

                  // if(site.data().name === 'SEOtest 10') {
                  //   console.log(site.data().due_end*1000);
                  // }

                  if(site.data().plan === 'free' || site.data().plan === 'Free' || !site.data().plan || changeToFree) {
                    this.freeSites.push({
                      id: site.id,
                      img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                      name: site.data().name ? site.data().name : site.data().WebsiteURL,
                      domainFixed: site.data().WebsiteURL,
                      project: eleUserProject.data().name,
                      statusSite: site.data().status,
                      domain: site.data().homepage,
                      projectid: eleUserProject.data().id,
                      color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                      own: 'me',
                      invitation: '',
                      imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                      plan: 'Free',
                      permission: 'owner',
                      amount: 'free',
                      nextDue: site.data().due_end ? moment(site.data().due_end*1000).format('MM/DD/YYYY') : '-',
                      nextDueCancel: site.data().due_end ? moment(site.data().due_end*1000).format('ll') : '-',
                      failPay: site.data().failPay ? site.data().failPay : false,
                      // nextDueCancel: site.data().due_end ? moment(site.data().due*1000).format('MMM DD') : '-',
                      cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                      downgrade_planed: downgrade_planed,
                      status: 'active',
                      payments: 'View Invoice',
                      freeTrial: site.data().freeTrial ? site.data().freeTrial : false,
                      customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                      customerIdSite: site.data().customerId ? site.data().customerId : '',
                      subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                      planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                      planSite: site.data().plan ? site.data().plan : '',
                      paidRecurrency: site.data().paidRecurrency ? (site.data().paidRecurrency === 'month' || site.data().paidRecurrency === 'Month' ? 'Monthly' : 'Anually') : ''
                    })
                  } else if(site.data().plan === 'basic' || site.data().plan === 'Basic' || site.data().plan === 'pro' || site.data().plan === 'Pro' || site.data().plan === 'custom' || site.data().plan === 'Custom') {
                    this.proSites.push({
                      id: site.id,
                      img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                      name: site.data().name ? site.data().name : site.data().WebsiteURL,
                      domainFixed: site.data().WebsiteURL,
                      project: eleUserProject.data().name,
                      statusSite: site.data().status,
                      domain: site.data().homepage,
                      projectid: eleUserProject.data().id,
                      color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                      own: 'me',
                      invitation: '',
                      imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                      plan: 'Pro',
                      permission: 'owner',
                      amount: '$20.00',
                      nextDue: site.data().due_end ? moment(site.data().due_end*1000).format('MM/DD/YYYY') : nextDue,
                      nextDueCancel: site.data().due_end ? moment(site.data().due_end*1000).format('ll') : '-',
                      failPay: site.data().failPay ? site.data().failPay : false,
                      cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                      downgrade_planed: downgrade_planed,
                      status: 'active',
                      payments: 'View Invoice',
                      freeTrial: site.data().freeTrial ? site.data().freeTrial : false,
                      customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                      customerIdSite: site.data().customerId ? site.data().customerId : '',
                      subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                      planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                      planSite: site.data().plan ? site.data().plan : '',
                      paidRecurrency: site.data().paidRecurrency ? (site.data().paidRecurrency === 'month' || site.data().paidRecurrency === 'Month' ? 'Monthly' : 'Anually') : '',
                      pages: site.data().planType ? site.data().planType : ''
                    })
                  }
                }
              })
            })
          })

          setTimeout(() => {
            this.loadingSites = false

            this.$nextTick(() => {
              $(".top-scroll-horizontal-license"+this.tabActive).scroll(() => {
                $(".vgt-responsive").scrollLeft($(".top-scroll-horizontal-license"+this.tabActive).scrollLeft());
              });

              $(".vgt-responsive").scroll(() => {
                $(".top-scroll-horizontal-license"+this.tabActive).scrollLeft($(".vgt-responsive").scrollLeft());
              });
            });
          }, 1300)
        })
      })
    }
  },
  mounted() {
    this.timerchangesSites = setInterval(() => {
      this.getStatusChangesSites()
    }, 600)
  },
  destroyed() {
    clearInterval(this.timerchangesSites)
  },
  methods: {
    getStatusChangesSites() {
      var getStorageChangesLicense = localStorage.getItem('siteLicenseChanges')
      if(getStorageChangesLicense) {
        localStorage.removeItem('siteLicenseChanges')
        this.constructSites()
      }

      setTimeout(() => {
        const primerElemento = document.querySelector('.table-wproject-sites-license'+this.tabActive);
        const segundoElemento = document.getElementById('tab-err-sc2'+this.tabActive);

        if(primerElemento) {
          const table = this.$el.querySelector('.table-wproject-sites-license'+this.tabActive)
          // console.log(primerElemento.offsetWidth, segundoElemento.style.width,table,table.offsetWidth);
          const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
          segundoElemento.style.width = ancho + 'px';
        }
      }, 500)
    },
    constructSites() {
      this.loadingSites = true
      this.freeSites = []
      this.basicSites = []
      this.proSites = []
      this.customSites = []
      // var nextDue = moment(Date.now()).add(1, 'month').format('MM-DD-YYYY');
      var arraySitesAdded = []
      const user = auth.currentUser
      db.collection('Users').doc(user.uid).get()
      .then(userDocCustomer => {
        this.userProjects.forEach(eleUserProject => {
          db.collection('Projects').doc(eleUserProject.id).collection('sites').get()
          .then(queryArchiveProject => {
            queryArchiveProject.forEach(site => {
              if(!arraySitesAdded.includes(site.data().WebsiteURL)) {
                arraySitesAdded.push(site.data().WebsiteURL)
                var changeToFree = false
                var statusCancel = site.data().cancelSub ? site.data().cancelSub : false
                if(statusCancel) {
                  // console.log(statusCancel, site.data().due*1000);
                  var dueCancel = moment(site.data().due_end*1000)
                  var dateActual = moment()

                  // Diferencia en días
                  var differenceInMinutes = dueCancel.diff(dateActual, 'minutes');
                  // console.log(differenceInMinutes, dueCancel, dateActual);
                  if(differenceInMinutes < 0) {
                    changeToFree = true
                    db.collection('Projects').doc(eleUserProject.id).collection('sites').doc(site.id).update({
                      failPay: false,
                      plan: 'Free',
                      planType: 0,
                      cancelSub: false
                    })
                  }
                }

                var downgrade_date = site.data().downgrade_date ? site.data().downgrade_date : false
                var downgrade_planed = false
                if(downgrade_date) {
                  // console.log(statusCancel, site.data().due*1000);
                  var dueCancel = moment(downgrade_date*1000)
                  var dateActual = moment()

                  // Diferencia en días
                  var differenceInMinutes = dueCancel.diff(dateActual, 'minutes');
                  // console.log(differenceInMinutes, dueCancel, dateActual);
                  if(differenceInMinutes < 0) {
                    downgrade_planed = false
                    db.collection('Projects').doc(eleUserProject.id).collection('sites').doc(site.id).update({
                      failPay: false,
                      downgrade_date: ''
                    })
                  } else {
                    downgrade_planed = true
                  }
                }

                if(site.data().plan === 'free' || site.data().plan === 'Free' || !site.data().plan || changeToFree) {
                  this.freeSites.push({
                    id: site.id,
                    img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                    name: site.data().name ? site.data().name : site.data().WebsiteURL,
                    domainFixed: site.data().WebsiteURL,
                    project: eleUserProject.name,
                    statusSite: site.data().status,
                    domain: site.data().homepage,
                    projectid: eleUserProject.id,
                    color: eleUserProject.color ? eleUserProject.color : 'rgb(240, 241, 247)',
                    own: 'me',
                    invitation: '',
                    imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                    plan: 'Free',
                    permission: 'owner',
                    amount: 'free',
                    nextDue: site.data().due_end ? moment(site.data().due_end*1000).format('MM/DD/YYYY') : '-',
                    nextDueCancel: site.data().due_end ? moment(site.data().due_end*1000).format('ll') : '-',
                    failPay: site.data().failPay ? site.data().failPay : false,
                    // nextDueCancel: site.data().due_end ? moment(site.data().due*1000).format('MMM DD') : '-',
                    cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                    downgrade_planed: downgrade_planed,
                    status: 'active',
                    payments: 'View Invoice',
                    freeTrial: site.data().freeTrial ? site.data().freeTrial : false,
                    customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                    customerIdSite: site.data().customerId ? site.data().customerId : '',
                    subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                    planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                    planSite: site.data().plan ? site.data().plan : '',
                    paidRecurrency: site.data().paidRecurrency ? (site.data().paidRecurrency === 'month' || site.data().paidRecurrency === 'Month' ? 'Monthly' : 'Anually') : ''
                  })
                } else if(site.data().plan === 'basic' || site.data().plan === 'Basic' || site.data().plan === 'pro' || site.data().plan === 'Pro' || site.data().plan === 'custom' || site.data().plan === 'Custom') {
                  this.proSites.push({
                    id: site.id,
                    img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                    name: site.data().name ? site.data().name : site.data().WebsiteURL,
                    domainFixed: site.data().WebsiteURL,
                    project: eleUserProject.name,
                    statusSite: site.data().status,
                    domain: site.data().homepage,
                    projectid: eleUserProject.id,
                    color: eleUserProject.color ? eleUserProject.color : 'rgb(240, 241, 247)',
                    own: 'me',
                    invitation: '',
                    imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                    plan: 'Pro',
                    permission: 'owner',
                    amount: '$20.00',
                    nextDue: site.data().due_end ? moment(site.data().due_end*1000).format('MM/DD/YYYY') : nextDue,
                    nextDueCancel: site.data().due_end ? moment(site.data().due_end*1000).format('ll') : '-',
                    failPay: site.data().failPay ? site.data().failPay : false,
                    cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                    downgrade_planed: downgrade_planed,
                    status: 'active',
                    payments: 'View Invoice',
                    freeTrial: site.data().freeTrial ? site.data().freeTrial : false,
                    customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                    customerIdSite: site.data().customerId ? site.data().customerId : '',
                    subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                    planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                    planSite: site.data().plan ? site.data().plan : '',
                    paidRecurrency: site.data().paidRecurrency ? (site.data().paidRecurrency === 'month' || site.data().paidRecurrency === 'Month' ? 'Monthly' : 'Anually') : '',
                    pages: site.data().planType ? site.data().planType : ''
                  })
                }
              }
            })
          })
        })
      })

      setTimeout(() => {
        this.loadingSites = false
      }, 1300)
    },
    updateMembership() {
      // console.log(this.planActive,this.planActive === '0' ? 'Free' : this.planActive === '1' ? 'Basic' : this.planActive === '2' ? 'Pro' : this.planActive === '3' ? 'Custom' : 'Free',this.idsite.id)
      db.collection('sites').doc(this.idsite.id).update({
        plan: this.planActive === 0 || this.planActive === '0' ? 'Free' : this.planActive === 1 || this.planActive === '1' ? 'Basic' : this.planActive === 2 || this.planActive === '2' ? 'Pro' : this.planActive === 3 || this.planActive === '3' ? 'Custom' : 'Free',
        status: this.statusSite === 'archive' ? 'active' : 'active'
      })
      .then(() => {
        const user = auth.currentUser
        if (user) {
          db.collection('Users').doc(user.uid).update({
            updated: Date.now()
          })
        }
        this.idsite = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'License updated',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
      .catch((error) => {
        console.log(error)
      })
    },
    logIdSite(val) {
      // console.log(val)
      this.idsite = val
      this.showOpen = true
    },
    logIdChangeProject(val) {
      // console.log('first')
      this.idsite = val
      this.showOpenChangeProject = true
    },
    logIdArchive(val) {
      this.idsite = val
      this.showOpenArchiveSite = true
    },
    logIdMembersProject(val) {
      this.idsite = val
      this.showOpenMembersProject = true
    },
    onRowSelected(items) {
      // if(items.column.field !== 'key' && items.column.field !== 'ownedBy' && items.column.field !== 'action') {
      // this.rowSiteSelected = []
      this.idsite = items.row
      // this.rowSiteSelected.push(items.row)
      // this.$router.push({ name: 'view-errors', params: {id: items.row.id} })
      // }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Key copied',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy key!',
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
  },
}
</script>

<style>
.tabsLicense
.nav-tabs
.nav-link.active {
  color: #232C51 !important;
  font-family: 'Hurme Geometric Sans 3';
  font-weight: 700 !important;
  padding-bottom: 4px;
  font-size: 19px;
  background: none !important;
}
.tabsLicense
.nav-tabs
.nav-link {
  font-weight: 450 !important;
  height: 37.58px !important;
}
.tabsLicense
.nav-tabs
.nav-link.active::after {
  background: transparent linear-gradient(90deg, #7838ff 0%, #30CDFF 100%) !important;
}
.tabsLicense .nav-tabs .nav-link:after {
  /* background: white !important; */
  background: #ddd !important;
  transform: translate3d(0, 0, 0);
}
.tabsLicense
.nav-tabs
.nav-link {
  color: #232C51 !important;
  /* font-family: ; */
  padding-bottom: 0px;
  font-size: 19px;
}
.url_link:hover {
  color: #A7AAB9 !important;
}
.thTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
}
.tdTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  padding: 0px !important;
  /* margin: 20px !important; */
}
.tdTableSitesKey {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
}
.tdTableSitesOwned {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
}
.thTableSitesMembership {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  width: 214px !important;
  max-width: 214px !important;
}
.tdTableSitesMembership {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  font-size: 12px;
  padding: 0px !important;
  word-break: break-all;
  cursor: pointer;
  vertical-align: middle !important;
  width: 214px !important;
  max-width: 214px !important;
}
.thTableSitesActionMembership {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  width: 80px !important;
  max-width: 80px !important;
}
.tdTableSitesActionMembership {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  font-size: 12px;
  padding: 0px !important;
  cursor: pointer;
  vertical-align: middle !important;
  width: 80px !important;
  max-width: 80px !important;
}
.vgt-table.bordered th {
  background: #fff !important;
  border: 1px solid transparent !important;
  margin-top: 20px !important;
  /* font-size: 16px !important; */
  /* color: #181E38 !important; */
}
.vgt-table tr:active {
  background: #f4f4f4 !important;
}
.vgt-table tr:target {
  background: #f4f4f4 !important;
}
.vgt-table tr:visited {
  background: #f4f4f4 !important;
}
.vgt-table tr:focus-visible {
  background: #f4f4f4 !important;
}
/* .vgt-table tr:hover {
    background: #f4f4f4 !important;
} */
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  padding: 0px !important;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
  padding: 0px !important;
}
.plan-active {
  background: #7838FF14;
  border: 1px solid #7838FF;
  border-radius: 10px;
}
.plan-inactive {
  background: #F0F1F7;
  border: 1px solid #DCDCF2;
  border-radius: 10px;
}
.thTableLicensesDomain {
  display: none !important;
  border: 1px solid transparent !important;
  border: 0px;
}
.tdTableLicensesDomain {
  display: none !important;
  border: 1px solid transparent !important;
  border: 0px;
}
.tdTableLicenses {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  padding: 0px !important;
  width: 110px !important;
  max-width: 110px !important;
  /* margin: 20px !important; */
}
/* .thTableLicenses {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
} */
.tableLicenses tr {
  border-right: 1px solid transparent;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
